/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PerformanceReviewBuilder = () => {
  // Review templates by role category
  const templates = {
    individual_contributor: {
      name: "Individual Contributor",
      sections: [
        "Technical Skills & Knowledge",
        "Quality of Work",
        "Productivity & Efficiency",
        "Problem Solving",
        "Communication",
        "Teamwork",
        "Initiative & Innovation",
        "Professional Development"
      ],
      competencies: {
        "Technical Skills & Knowledge": [
          "Technical expertise in role-specific tools and technologies",
          "Application of professional knowledge",
          "Keeping up with industry trends and best practices",
          "Quality and accuracy of technical work"
        ],
        "Quality of Work": [
          "Attention to detail",
          "Adherence to standards and procedures",
          "Consistency in deliverables",
          "Error detection and correction"
        ],
        "Productivity & Efficiency": [
          "Meeting deadlines and commitments",
          "Work output volume",
          "Time management",
          "Resource utilization"
        ],
        "Problem Solving": [
          "Analysis and critical thinking",
          "Solution development",
          "Decision making",
          "Handling complex situations"
        ],
        "Communication": [
          "Written communication clarity",
          "Verbal communication effectiveness",
          "Active listening",
          "Documentation quality"
        ],
        "Teamwork": [
          "Collaboration with colleagues",
          "Contributing to team goals",
          "Supporting team members",
          "Conflict resolution"
        ],
        "Initiative & Innovation": [
          "Self-direction",
          "Process improvement suggestions",
          "Creative problem solving",
          "Taking on additional responsibilities"
        ],
        "Professional Development": [
          "Skill development",
          "Goal setting and achievement",
          "Adaptability to change",
          "Knowledge sharing"
        ]
      }
    },
    manager: {
      name: "Manager/Team Lead",
      sections: [
        "Leadership & Direction",
        "Team Development",
        "Strategic Planning",
        "Project Management",
        "Communication & Influence",
        "Business Acumen",
        "Change Management",
        "Results Orientation"
      ],
      competencies: {
        "Leadership & Direction": [
          "Setting clear expectations and goals",
          "Providing guidance and support",
          "Decision making effectiveness",
          "Creating team vision and alignment"
        ],
        "Team Development": [
          "Coaching and mentoring",
          "Performance management",
          "Talent development",
          "Team motivation and engagement"
        ],
        "Strategic Planning": [
          "Goal setting and strategy development",
          "Resource planning and allocation",
          "Risk assessment and management",
          "Long-term planning"
        ],
        "Project Management": [
          "Project planning and execution",
          "Resource optimization",
          "Timeline and budget management",
          "Stakeholder management"
        ],
        "Communication & Influence": [
          "Upward and downward communication",
          "Stakeholder relationship management",
          "Conflict resolution",
          "Change communication"
        ],
        "Business Acumen": [
          "Understanding business objectives",
          "Budget management",
          "Market awareness",
          "Strategic decision making"
        ],
        "Change Management": [
          "Leading organizational change",
          "Adaptability and flexibility",
          "Change impact assessment",
          "Managing resistance to change"
        ],
        "Results Orientation": [
          "Goal achievement",
          "Performance metrics management",
          "Continuous improvement",
          "Accountability"
        ]
      }
    },
    executive: {
      name: "Executive/Director",
      sections: [
        "Strategic Leadership",
        "Organizational Development",
        "Business Growth",
        "Stakeholder Management",
        "Innovation & Change",
        "Financial Management",
        "Risk & Compliance",
        "Industry Leadership"
      ],
      competencies: {
        "Strategic Leadership": [
          "Vision and strategy development",
          "Organizational alignment",
          "Executive decision making",
          "Building high-performance culture"
        ],
        "Organizational Development": [
          "Talent strategy",
          "Succession planning",
          "Organizational design",
          "Culture development"
        ],
        "Business Growth": [
          "Market expansion",
          "Revenue growth",
          "Business development",
          "Innovation strategy"
        ],
        "Stakeholder Management": [
          "Board relations",
          "Investor relations",
          "Partner management",
          "Community relations"
        ],
        "Innovation & Change": [
          "Digital transformation",
          "Change leadership",
          "Innovation culture",
          "Future readiness"
        ],
        "Financial Management": [
          "P&L responsibility",
          "Investment decisions",
          "Cost optimization",
          "Financial planning"
        ],
        "Risk & Compliance": [
          "Risk management",
          "Regulatory compliance",
          "Corporate governance",
          "Ethics and integrity"
        ],
        "Industry Leadership": [
          "Industry influence",
          "Thought leadership",
          "Market positioning",
          "Competitive strategy"
        ]
      }
    }
  }

  // Rating scale definitions
  const ratingScale = {
    5: "Outstanding - Consistently exceeds all expectations",
    4: "Exceeds Expectations - Often surpasses job requirements",
    3: "Meets Expectations - Fulfills all job requirements",
    2: "Needs Improvement - Partially meets job requirements",
    1: "Unsatisfactory - Does not meet basic job requirements"
  }

  // State management
  const [reviewData, setReviewData] = useState({
    employeeName: "",
    employeeRole: "",
    reviewerName: "",
    reviewDate: "",
    roleCategory: "individual_contributor",
    ratings: {},
    comments: {},
    overallRating: "",
    developmentPlan: {
      strengths: "",
      areasForImprovement: "",
      goals: "",
      supportNeeded: ""
    },
    summary: ""
  })

  // Handle input changes
  const handleInputChange = (field, value) => {
    setReviewData(prev => ({
      ...prev,
      [field]: value
    }))
  }

  // Handle rating changes
  const handleRatingChange = (section, competency, value) => {
    setReviewData(prev => ({
      ...prev,
      ratings: {
        ...prev.ratings,
        [section]: {
          ...prev.ratings[section],
          [competency]: value
        }
      }
    }))
  }

  // Handle comment changes
  const handleCommentChange = (section, value) => {
    setReviewData(prev => ({
      ...prev,
      comments: {
        ...prev.comments,
        [section]: value
      }
    }))
  }

  // Calculate section average
  const calculateSectionAverage = (section) => {
    const sectionRatings = reviewData.ratings[section]
    if (!sectionRatings) return 0
    const values = Object.values(sectionRatings).map(Number)
    if (values.length === 0) return 0
    return values.reduce((a, b) => a + b, 0) / values.length
  }

  // Calculate overall rating
  const calculateOverallRating = () => {
    const template = templates[reviewData.roleCategory]
    const sectionAverages = template.sections.map(calculateSectionAverage)
    const validAverages = sectionAverages.filter(avg => avg > 0)
    if (validAverages.length === 0) return 0
    return validAverages.reduce((a, b) => a + b, 0) / validAverages.length
  }

  return (
    <Layout className="performance-review-builder-page">
      <SEO 
        title="Performance Review Builder | Create Fair and Comprehensive Reviews" 
        description="Build professional performance reviews with role-specific templates, evaluation criteria, and manager guidance."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Performance Review Builder
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Tool</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Create comprehensive and fair performance reviews using role-specific templates and evaluation criteria. 
            This tool helps managers:
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Structure reviews consistently across the organization</li>
            <li>Evaluate performance objectively across key competencies</li>
            <li>Provide specific and actionable feedback</li>
            <li>Create meaningful development plans</li>
          </ul>
        </div>

        {/* Review Information */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Review Information</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
            <div>
              <label htmlFor="employeeName" sx={{ display: 'block', mb: 2 }}>
                Employee Name
              </label>
              <input
                type="text"
                id="employeeName"
                value={reviewData.employeeName}
                onChange={(e) => handleInputChange('employeeName', e.target.value)}
                placeholder="Enter employee name"
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px'
                }}
              />
            </div>

            <div>
              <label htmlFor="employeeRole" sx={{ display: 'block', mb: 2 }}>
                Employee Role/Title
              </label>
              <input
                type="text"
                id="employeeRole"
                value={reviewData.employeeRole}
                onChange={(e) => handleInputChange('employeeRole', e.target.value)}
                placeholder="Enter role or title"
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px'
                }}
              />
            </div>

            <div>
              <label htmlFor="reviewerName" sx={{ display: 'block', mb: 2 }}>
                Reviewer Name
              </label>
              <input
                type="text"
                id="reviewerName"
                value={reviewData.reviewerName}
                onChange={(e) => handleInputChange('reviewerName', e.target.value)}
                placeholder="Enter reviewer name"
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px'
                }}
              />
            </div>

            <div>
              <label htmlFor="reviewDate" sx={{ display: 'block', mb: 2 }}>
                Review Date
              </label>
              <input
                type="date"
                id="reviewDate"
                value={reviewData.reviewDate}
                onChange={(e) => handleInputChange('reviewDate', e.target.value)}
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px'
                }}
              />
            </div>

            <div>
              <label htmlFor="roleCategory" sx={{ display: 'block', mb: 2 }}>
                Role Category
                <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                  Select the template that best matches the role
                </span>
              </label>
              <select
                id="roleCategory"
                value={reviewData.roleCategory}
                onChange={(e) => handleInputChange('roleCategory', e.target.value)}
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px'
                }}
              >
                {Object.entries(templates).map(([key, template]) => (
                  <option key={key} value={key}>{template.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Rating Scale Guide */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Rating Scale Guide</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 3 }}>
            {Object.entries(ratingScale).map(([rating, description]) => (
              <div key={rating} sx={{ 
                p: 3, 
                border: '1px solid',
                borderColor: 'gray.2',
                borderRadius: '4px'
              }}>
                <strong sx={{ fontSize: '1.1rem', color: 'primary' }}>{rating}</strong>
                <p sx={{ mt: 2, fontSize: '0.9rem' }}>{description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Competency Evaluation */}
        {templates[reviewData.roleCategory].sections.map(section => (
          <div key={section} sx={{ 
            bg: 'white', 
            p: 4, 
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            mb: 4
          }}>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>{section}</h2>
            
            {/* Competencies */}
            <div sx={{ mb: 4 }}>
              {templates[reviewData.roleCategory].competencies[section].map((competency, index) => (
                <div key={competency} sx={{ mb: 3 }}>
                  <label htmlFor={`${section}-${index}`} sx={{ display: 'block', mb: 2 }}>
                    {competency}
                  </label>
                  <select
                    id={`${section}-${index}`}
                    value={reviewData.ratings[section]?.[competency] || ""}
                    onChange={(e) => handleRatingChange(section, competency, e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="">Select rating</option>
                    {Object.entries(ratingScale).map(([rating, description]) => (
                      <option key={rating} value={rating}>{rating} - {description}</option>
                    ))}
                  </select>
                </div>
              ))}
            </div>

            {/* Section Comments */}
            <div>
              <label htmlFor={`${section}-comments`} sx={{ display: 'block', mb: 2 }}>
                Comments and Examples
                <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                  Provide specific examples and feedback for this area
                </span>
              </label>
              <textarea
                id={`${section}-comments`}
                value={reviewData.comments[section] || ""}
                onChange={(e) => handleCommentChange(section, e.target.value)}
                placeholder="Enter comments and specific examples..."
                rows={4}
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px',
                  resize: 'vertical'
                }}
              />
            </div>

            {/* Section Average */}
            <div sx={{ mt: 3, p: 3, bg: 'gray.1', borderRadius: '4px' }}>
              <p sx={{ fontSize: '1.1rem' }}>
                Section Average: <strong>{calculateSectionAverage(section).toFixed(2)}</strong>
              </p>
            </div>
          </div>
        ))}

        {/* Overall Rating */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Overall Rating</h2>
          <div sx={{ 
            p: 4, 
            bg: 'gray.1', 
            borderRadius: '4px',
            textAlign: 'center'
          }}>
            <p sx={{ fontSize: '2rem', fontWeight: 'bold', color: 'primary' }}>
              {calculateOverallRating().toFixed(2)}
            </p>
            <p sx={{ mt: 2, fontSize: '1.1rem' }}>
              {ratingScale[Math.round(calculateOverallRating())]}
            </p>
          </div>
        </div>

        {/* Development Plan */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Development Plan</h2>
          
          <div sx={{ mb: 4 }}>
            <label htmlFor="strengths" sx={{ display: 'block', mb: 2 }}>
              Key Strengths
              <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                Highlight the employee's main strengths and achievements
              </span>
            </label>
            <textarea
              id="strengths"
              value={reviewData.developmentPlan.strengths}
              onChange={(e) => handleInputChange('developmentPlan', {
                ...reviewData.developmentPlan,
                strengths: e.target.value
              })}
              placeholder="Enter key strengths..."
              rows={4}
              sx={{
                width: '100%',
                p: 2,
                border: '1px solid',
                borderColor: 'gray.3',
                borderRadius: '4px',
                resize: 'vertical'
              }}
            />
          </div>

          <div sx={{ mb: 4 }}>
            <label htmlFor="improvements" sx={{ display: 'block', mb: 2 }}>
              Areas for Improvement
              <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                Identify specific areas that need development
              </span>
            </label>
            <textarea
              id="improvements"
              value={reviewData.developmentPlan.areasForImprovement}
              onChange={(e) => handleInputChange('developmentPlan', {
                ...reviewData.developmentPlan,
                areasForImprovement: e.target.value
              })}
              placeholder="Enter areas for improvement..."
              rows={4}
              sx={{
                width: '100%',
                p: 2,
                border: '1px solid',
                borderColor: 'gray.3',
                borderRadius: '4px',
                resize: 'vertical'
              }}
            />
          </div>

          <div sx={{ mb: 4 }}>
            <label htmlFor="goals" sx={{ display: 'block', mb: 2 }}>
              Development Goals
              <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                Set specific, measurable goals for the next period
              </span>
            </label>
            <textarea
              id="goals"
              value={reviewData.developmentPlan.goals}
              onChange={(e) => handleInputChange('developmentPlan', {
                ...reviewData.developmentPlan,
                goals: e.target.value
              })}
              placeholder="Enter development goals..."
              rows={4}
              sx={{
                width: '100%',
                p: 2,
                border: '1px solid',
                borderColor: 'gray.3',
                borderRadius: '4px',
                resize: 'vertical'
              }}
            />
          </div>

          <div>
            <label htmlFor="support" sx={{ display: 'block', mb: 2 }}>
              Support and Resources Needed
              <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                Identify what support or resources will help achieve these goals
              </span>
            </label>
            <textarea
              id="support"
              value={reviewData.developmentPlan.supportNeeded}
              onChange={(e) => handleInputChange('developmentPlan', {
                ...reviewData.developmentPlan,
                supportNeeded: e.target.value
              })}
              placeholder="Enter required support and resources..."
              rows={4}
              sx={{
                width: '100%',
                p: 2,
                border: '1px solid',
                borderColor: 'gray.3',
                borderRadius: '4px',
                resize: 'vertical'
              }}
            />
          </div>
        </div>

        {/* Overall Summary */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Overall Summary</h2>
          <label htmlFor="summary" sx={{ display: 'block', mb: 2 }}>
            Overall Summary
          </label>
          <textarea
            id="summary"
            value={reviewData.summary}
            onChange={(e) => handleInputChange('summary', e.target.value)}
            placeholder="Provide an overall summary of the employee's performance..."
            rows={6}
            sx={{
              width: '100%',
              p: 2,
              border: '1px solid',
              borderColor: 'gray.3',
              borderRadius: '4px',
              resize: 'vertical'
            }}
          />
        </div>

        {/* Action Buttons */}
        <div sx={{ 
          display: 'flex', 
          gap: 3,
          justifyContent: 'flex-end',
          mb: 4
        }}>
          <button
            type="button"
            onClick={() => window.print()}
            sx={{
              px: 4,
              py: 2,
              bg: 'primary',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '1rem',
              '&:hover': {
                bg: 'primaryDark'
              }
            }}
          >
            Print Review
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default PerformanceReviewBuilder 